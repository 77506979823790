.userContainer {
    max-width: 1440px;
    margin: 0 auto;
}

.usersList img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    object-position: center;
}

.usersListHeader {
    border: 1px solid lightgray;
}
.usersListHeaderBorderRight {
    border-right: 1px solid lightgray;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
}
.usersListHeaderBorderBottom {
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
}

.usersListFamily {
    display: flex;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid lightgray;
    align-items: center;
    justify-content: space-between;
}

.userFamily {
    display: flex;
    align-items: center;
}