.loginContainer {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.loginForm {
    width: 500px;
    padding: 50px;
    border: 1px solid lightgray;
}