.dietContainer {
    max-width: 1440px;
    margin: 0 auto;
}

.dietsList img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    object-position: center;
}

.dietsListHeader {
    border: 1px solid lightgray;
}
.dietsListHeaderBorderRight {
    border-right: 1px solid lightgray;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
}
.dietsListHeaderBorder {
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
}
.dietsListHeaderBorderBottom {
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
}