.brandContainer {
    max-width: 1440px;
    margin: 0 auto;
}

.brandsList img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    object-position: center;
}

.brandsListHeader {
    border: 1px solid lightgray;
}
.brandsListHeaderBorderRight {
    border-right: 1px solid lightgray;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
}
.brandsListHeaderBorder {
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
}
.brandsListHeaderBorderBottom {
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
}