.animalListMutipleSelect {
  display: flex;
  flex-wrap: wrap;
}
.animalListMutipleSelectOption {
  width: calc(100% / 3);
  padding: 5px;
  box-sizing: border-box;
}
.animalListMutipleSelectOptioContent {
  border: 1px solid black;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
.animalListMutipleSelectOptioContentActive {
  background-color: lightgreen;
}
