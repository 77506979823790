.product_form_input_none {
  display: none !important;
}

.product_form_image {
  height: 200px;
  width: 300px;
  border: 1px solid lightgrey;
  display: flex;
  color: lightgrey;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  object-position: center;
}
