header {
    border-bottom: 1px solid lightgray;
}
.headerContainer {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
}

header img {
    height: 100px;
}

.headerNav a{
    padding: 10px;
    color: black;
    text-decoration: none;
}